import { Box, BoxProps } from '@mantine/core'
import { PropsWithChildren } from 'react'

function PanelBody (props: PropsWithChildren & BoxProps) {
  const {
    children,
    ...others
  } = props
  return (
    <Box
      p="sm"
      {...others}
      style={{
        maxHeight: '100%',
        overflow: 'auto'
      }}
    >
      {children}
    </Box>
  )
}

export default PanelBody
