import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useMemo, useState } from 'react'
import { BTN_SHARING_ID } from '../../../enums/UI'
import ConfigurableUIComponent from '../../ConfigurableUIComponent'
import MapToolbarButton from '../../map/MapToolbarButton'
import useMapContext from '../../map/useMapContext'
import ShareModal from './ShareModal'
import useMapUrl from '../../../hooks/useMapUrl'
import { getMapCode, MapURLOptions } from './sharing'
import IntegrateModal, { IframeTools, MapIframeOptions } from './IntegrateModal'
import useSearchContext from '../search/useSearchContext'

/**
 * Bouton de partage de la vue de carte.
 */
function MapShareButton () {
  const { map } = useMapContext()
  const { searchQuery } = useSearchContext()
  const [active, setActive] = useState<boolean>(false)
  const [isIframeModalOpen, setIsIframeModalOpen] = useState<boolean>(false)

  const closeShareModal = useCallback(() => {
    setActive(false)
  }, [])

  const toggleButton = useCallback(() => {
    setActive((s) => !s)
  }, [])

  const toggleIframeModal = useCallback(() => {
    setIsIframeModalOpen((s) => !s)
  }, [])

  // Prépare les options du lien de partage.
  const mapOptions = useMemo<MapURLOptions>(() => ({
    search: searchQuery
  }), [searchQuery])

  const iframeOptions = useMemo<MapIframeOptions>(() => ({
    ...mapOptions,
    embed: true,
    buttons: IframeTools.map((el) => el.id),
    height: 600,
    width: 800
  }), [mapOptions])

  const mapUrl = useMapUrl(mapOptions)
  const iframeUrl = useMapUrl({ ...mapOptions, embed: true })
  const mapCode = useMemo<string>(() => getMapCode(iframeUrl), [iframeUrl])

  return (
    <>
      <ConfigurableUIComponent
        id={BTN_SHARING_ID}
        showByDefault
      >
        <MapToolbarButton
          active={active}
          onClick={toggleButton}
          title="Partager la carte"
        >
          <FontAwesomeIcon
            icon={faShareAlt}
            fixedWidth
          />
        </MapToolbarButton>
      </ConfigurableUIComponent>

      {map
        ? <>
          <ShareModal
            code={mapCode}
            link={mapUrl}
            onClose={closeShareModal}
            onIframeSettingsClick={toggleIframeModal}
            show={active}
          />
          <IntegrateModal
            onClose={toggleIframeModal}
            options={iframeOptions}
            show={isIframeModalOpen}
          />
        </>
        : null}
    </>
  )
}

export default MapShareButton
