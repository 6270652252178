import OlMap from 'ol/Map'
import { useCallback, useState } from 'react'
import useDebouncedCallback from '../../hooks/useDebouncedCallback'
import useOLEventListener from '../../hooks/useOLEventListener'
import useThrottledCallback from '../../hooks/useThrottledCallback'
import useUserFormattedCoordinateList from '../../hooks/useUserFormattedCoordinateList'
import MapBrowserEvent from 'ol/MapBrowserEvent'
import { Coordinate } from 'ol/coordinate'

export type MapPointerCoordinateProps = {
  map: OlMap
}

/**
 * Affiche les coordonnées du curseur sur la carte.
 */
function MapPointerCoordinate (props: MapPointerCoordinateProps) {
  const { map } = props
  const [coordinate, setCoordinate] = useState<Coordinate>()

  const list = useUserFormattedCoordinateList(coordinate, {
    projection: map.getView().getProjection().getCode()
  })

  const handlePointerMove = useThrottledCallback(useCallback((event: MapBrowserEvent<MouseEvent>) => {
    setCoordinate(event.coordinate)
  }, []), 50)

  useOLEventListener(map, 'pointermove', handlePointerMove)

  const handlePointerMoveEnd = useDebouncedCallback(useCallback((event: MapBrowserEvent<MouseEvent>) => {
    setCoordinate(event.coordinate)
  }, []), 100)

  useOLEventListener(map, 'pointermove', handlePointerMoveEnd)

  if (coordinate == null) {
    return null
  }

  return (
    <div className="ol-coordinates ol-unselectable ol-overlaycontainer-stopevent">
      {list.map((el) => (
        <div key={el.value} title={el.label}>{el.value}</div>
      ))}
    </div>
  )
}

export default MapPointerCoordinate
