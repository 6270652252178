import { useCallback, useState } from 'react'
import useDebouncedCallback from './useDebouncedCallback'
import useOLEventListener from './useOLEventListener'
import Collection from 'ol/Collection'

function useOLCollection<T> (collection: Collection<T>) {
  const [array, setArray] = useState<T[]>(() => [...collection.getArray()])

  const handleChange = useDebouncedCallback(useCallback(() => {
    setArray([...collection.getArray()])
  }, [collection]), 100)

  useOLEventListener(collection, 'add', handleChange)
  useOLEventListener(collection, 'change', handleChange)
  useOLEventListener(collection, 'remove', handleChange)

  return array
}

export default useOLCollection
