import { useCallback, useEffect, useRef } from 'react'

function useThrottledCallback (
  func: (...args: unknown[]) => unknown,
  delay: number
): (...args: unknown[]) => unknown {
  const funcRef = useRef(null)
  const lastCalledRef = useRef(null)

  // 10 par seconde
  const callback = useCallback((...args: unknown[]) => {
    if (!lastCalledRef.current || lastCalledRef.current + delay <= Date.now()) {
      if (typeof funcRef.current === 'function') {
        funcRef.current(...args)
        lastCalledRef.current = Date.now()
      }
    }
  }, [delay])

  useEffect(() => {
    funcRef.current = func
  }, [func])

  return callback
}

export default useThrottledCallback
