import { useCallback, useEffect, useRef } from 'react'

/**
 * Permet de limiter le nombre d'appels successifs à une fonction,
 * afin de ne l'exécuter qu'une seule fois.
 * @param func
 * @param delay
 */
function useDebouncedCallback (func: (...args: unknown[]) => unknown, delay: number = 50) {
  const funcRef = useRef(null)
  const timerRef = useRef(null)

  const callback = useCallback((...args: unknown[]) => {
    if (timerRef.current != null) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(() => {
      if (typeof funcRef.current === 'function') {
        funcRef.current(...args)
      }
    }, delay)
  }, [delay])

  useEffect(() => {
    funcRef.current = func
  }, [func])

  useEffect(() =>
    () => {
      if (timerRef.current != null) {
        clearTimeout(timerRef.current)
      }
    }, [])

  return callback
}

export default useDebouncedCallback
