import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inputValue } from '@jalik/react-form'
import {
  Badge,
  Box,
  Checkbox,
  Group,
  Modal,
  NumberInput,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
  TextInput
} from '@mantine/core'
import { useClipboard } from '@mantine/hooks'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  BTN_DOWNLOAD_ID,
  BTN_DRAWING_ID,
  BTN_FULLSCREEN_ID,
  BTN_LAYERS_ID,
  BTN_MEASURE_ID,
  BTN_POSITION_ID,
  BTN_PREFERENCES_ID,
  BTN_SEARCH_ID,
  BTN_SHARING_ID,
  BTN_ZOOM_BOX_ID,
  BTN_ZOOM_ID
} from '../../../enums/UI'
import { EMBED_MAX_SIZE } from '../../../hooks/useEmbeddedStyles'
import useObjectState from '../../../hooks/useObjectState'
import { handleValueChange } from '../../../lib/form'
import { getMapCode, MapURLOptions } from './sharing'
import useMapUrl from '../../../hooks/useMapUrl'

/**
 * Liste des outils activables dans la modale d'iframe.
 */
export const IframeTools = [
  {
    id: BTN_DOWNLOAD_ID,
    label: 'Téléchargement',
    description: 'Permet de télécharger la carte sous forme d\'image.'
  },
  {
    id: BTN_DRAWING_ID,
    label: 'Dessin',
    description: 'Permet de dessiner des objets sur la carte.'
  },
  {
    id: BTN_MEASURE_ID,
    label: 'Mesure',
    description: 'Permet de mesurer des objets sur la carte.'
  },
  {
    id: BTN_FULLSCREEN_ID,
    label: 'Plein écran',
    description: 'Permet d\'afficher la carte en plein écran.'
  },
  {
    id: BTN_LAYERS_ID,
    label: 'Cartes',
    description: 'Permet de gérer l\'affichage des couches de la carte.'
  },
  {
    id: BTN_POSITION_ID,
    label: 'Géolocalisation',
    description: 'Permet d\'afficher les coordonnées GPS sur la carte.'
  },
  {
    id: BTN_PREFERENCES_ID,
    label: 'Préférences',
    description: 'Permet de modifier les préférences d\'affichage.'
  },
  {
    id: BTN_SEARCH_ID,
    label: 'Recherche',
    description: 'Permet de rechercher des adresses, lieux, coordonnées...'
  },
  {
    id: BTN_SHARING_ID,
    label: 'Partage',
    description: 'Permet de partager un point sur la carte.'
  },
  {
    id: BTN_ZOOM_ID,
    label: 'Zoom avant/arrière',
    description: 'Permet de zoomer en avant ou en arrière.'
  },
  {
    id: BTN_ZOOM_BOX_ID,
    label: 'Zoom rectangulaire',
    description: 'Permet de zoomer sur une zone de la carte.'
  }
]

/**
 * Retourne la valeur booléenne ou false.
 * @param value
 */
function inputBool (value: unknown): boolean {
  return typeof value === 'boolean' ? value : false
}

export type MapIframeOptions = MapURLOptions & {
  height: number
  includeInfo?: boolean
  includeSearch?: boolean
  width: number
}

export type IntegrateModalProps = {
  onClose (): void
  options: MapIframeOptions
  show: boolean
}

/**
 * Modale d'intégration de la carte.
 * @param props
 */
function IntegrateModal (props: IntegrateModalProps) {
  const {
    onClose,
    options,
    show
  } = props

  const [fields, setFields] = useObjectState(options)

  const codeClipboard = useClipboard({ timeout: 3000 })

  const tools = useMemo(() => (
    IframeTools.sort((a, b) => (a.label.localeCompare(b.label)))
  ), [])

  const url = useMapUrl({
    ...fields,
    info: fields.includeInfo ? fields.info : undefined,
    search: fields.includeSearch ? fields.search : undefined
  })

  const code = useMemo<string>(() => (
    getMapCode(url, fields.width, fields.height)
  ), [fields.height, fields.width, url])

  const handleOptionsChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event
    setFields({ [currentTarget.name]: currentTarget.checked })
  }, [setFields])

  const handleToolsChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = event
    const toolId = currentTarget.value
    let buttons

    if (currentTarget.checked) {
      buttons = [...fields.buttons, toolId]
    } else {
      buttons = fields.buttons.filter((el) => el !== toolId)
    }
    setFields({ buttons })
  }, [fields, setFields])

  const setFieldValue = useCallback((name: string, value: unknown) => {
    setFields({ [name]: value })
  }, [setFields])

  useEffect(() => {
    if (options) {
      setFields(options)
    }
  }, [options, setFields])

  return (
    <Modal
      centered
      opened={show}
      onClose={onClose}
      size="100%"
      title="Intégration de la carte"
      scrollAreaComponent={Box}
    >
      <SimpleGrid
        cols={{
          base: 1,
          lg: 2
        }}
      >
        <Stack>
          <Group>
            <Badge variant="outline">
              1
            </Badge>
            <Text>
              Entrez les dimensions de la carte
            </Text>
          </Group>
          <Group grow>
            <NumberInput
              data-type="number"
              id="iframe-width-field"
              min={400}
              max={EMBED_MAX_SIZE}
              name="width"
              label="Largeur"
              onChange={handleValueChange('width', setFieldValue)}
              step={1}
              value={inputValue(fields.width)}
            />
            <NumberInput
              data-type="number"
              min={400}
              max={EMBED_MAX_SIZE}
              name="height"
              label="Hauteur"
              onChange={handleValueChange('height', setFieldValue)}
              step={1}
              value={inputValue(fields.height)}
            />
          </Group>

          <Group>
            <Badge variant="outline">
              2
            </Badge>
            <Text>
              Sélectionnez les options :
            </Text>
          </Group>
          <Stack gap="xs">
            <Checkbox
              checked={inputBool(fields.fixed)}
              data-type="number"
              id="iframe-options-fixed-field"
              label="Figer la carte"
              name="fixed"
              onChange={handleOptionsChange}
              type="checkbox"
              value="1"
            />
            <Checkbox
              checked={inputBool(fields.includeInfo)}
              disabled={!fields.info}
              data-type="number"
              id="iframe-options-info-field"
              name="includeInfo"
              label="Inclure le marqueur d'information"
              onChange={handleOptionsChange}
              type="checkbox"
              value="1"
            />
            <Checkbox
              checked={inputBool(fields.includeSearch)}
              disabled={!fields.search}
              data-type="number"
              id="iframe-options-search-field"
              name="includeSearch"
              label="Inclure les résultats de recherche"
              onChange={handleOptionsChange}
              type="checkbox"
              value="1"
            />
          </Stack>

          <Group>
            <Badge variant="outline">
              3
            </Badge>
            <Text>
              Sélectionnez les fonctionnalités à intégrer :
            </Text>
          </Group>
          <ScrollArea>
            <SimpleGrid
              cols={{
                base: 1,
                md: 2
              }}
            >
              {tools.map((tool) => (
                <Checkbox
                  key={tool.label}
                  checked={
                    inputBool(fields.buttons.indexOf(tool.id) !== -1)
                  }
                  data-type="string"
                  id={`iframe-tool-${tool.id}-field`}
                  name="buttons"
                  label={tool.label}
                  description={tool.description}
                  onChange={handleToolsChange}
                  type="checkbox"
                  value={tool.id}
                />
              ))}
            </SimpleGrid>
          </ScrollArea>

          <Group>
            <Badge variant="outline">
              4
            </Badge>
            <Text>
              Copiez le code suivant dans une page HTML :
            </Text>
          </Group>
          <TextInput
            id="iframe-code-field"
            onFocus={() => codeClipboard.copy(code)}
            readOnly
            value={inputValue(code)}
            rightSectionPointerEvents="none"
            rightSection={(
              <Text c={codeClipboard.copied ? 'green' : undefined}>
                <FontAwesomeIcon
                  icon={codeClipboard.copied
                    ? faCheck
                    : faCopy}
                />
              </Text>
            )}
          />
        </Stack>

        <Stack
          h="100%"
          pos="relative"
          bg="gray.1"
          // maw={EMBED_MAX_SIZE}
          justify="center"
          align="center"
        >
          <iframe
            allow="geolocation; fullscreen"
            src={url}
            style={{
              maxWidth: fields.width || EMBED_MAX_SIZE,
              maxHeight: fields.height || EMBED_MAX_SIZE,
              border: 'none',
              width: '100%',
              height: '100%'
            }}
            title="Intégration de carte"
          />
        </Stack>
      </SimpleGrid>
    </Modal>
  )
}

export default IntegrateModal
